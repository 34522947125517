// @flow

import React, { useContext } from 'react';
import Helmet from 'react-helmet';
import { BrowserSupport } from '@latitude/browser-support';
import { Footer } from '@latitude/footer';
import { BrokerHeader } from './BrokerHeader';
import GemHeader from './Header/GemHeader';
import footerContent from '../data/json/footer.json';
import brokerHeaderData from '../data/pages/brokers/broker-header';
import { PageContext } from '../context/PageContext';
import Alert from '@/components/lab-components/Alert';
import '../../sass/style.scss';

const Layout = props => {
  /** Contentful Global Components in Template */
  const pageData = useContext(PageContext);
  const { header, footer } = pageData?.template || {};
  const { alertData } = pageData?.updatedBody || {};
  const selectedFooter = footer
    ? footer
    : props?.customFooter
    ? props.customFooter
    : footerContent;
  /** - END - */

  return (
    <React.Fragment>
      <Helmet>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="format-detection" content="telephone=no" />
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />

        {/* <!-- Google Fonts --> */}
        <link
          href="https://fonts.googleapis.com/css?family=Roboto+Slab:400,700|Roboto:300,400,500,700"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:400,800,600&display=swap"
          rel="stylesheet"
        />
        <script
          type="text/javascript"
          src="https://www.bugherd.com/sidebarv2.js?apikey=k8x4tdkkpaylbl47h1vpyq"
          async="true"
        />
      </Helmet>

      <BrowserSupport />

      {!props.noHeader && <GemHeader {...header} />}
      {props.brokerHeader && <BrokerHeader headerData={brokerHeaderData} />}
      {alertData && (
        <div
          className={`alert-offset ${
            props.hasSecNav ? 'alert-offset--spacer' : ''
          } ${props.hasStickyNav ? 'alert-offset--mobile' : ''}`}
        >
          <Alert {...alertData} />
        </div>
      )}

      {props.children}

      {!props.noFooter && <Footer {...selectedFooter} isNz />}
    </React.Fragment>
  );
};

export default Layout;
